import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import TypingEffect from "./common/TypingEffect";
import TypingEffect2 from "./common/TypingEffect2";
const About = () => {
    return (
      <div className="about-container container">
        <div className="header-content">
          <p className="subtitle">속지마세요, 눈밤에서 시작하세요.</p>
          <TypingEffect text={`코드 하나로 창조하는\n무한한 가능성`}/>
        </div>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          loop={true}
          speed={15000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: false,
          }}
          allowTouchMove={false}
          breakpoints={{
            0: { // 768px 이하일 때 설정
              slidesPerView: 2,
            },
            769: { // 769px 이상일 때 설정
              slidesPerView: 3,
            },
          }}
          className="banner banner-1"
        >
          {/* 텍스트 반복을 위해 충분한 슬라이드 추가 */}
          {Array(10).fill().map((_, index) => (
            <SwiperSlide key={index}>
              <p className="banner-text">Limitless possibilities created through code.</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          loop={true}
          speed={15000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          allowTouchMove={false}
          breakpoints={{
            0: { // 768px 이하일 때 설정
              slidesPerView: 2,
            },
            769: { // 769px 이상일 때 설정
              slidesPerView: 3,
            },
          }}
          className="banner banner-2"
        >
          {/* 텍스트 반복을 위해 충분한 슬라이드 추가 */}
          {Array(10).fill().map((_, index) => (
            <SwiperSlide key={index}>
              <p className="banner-text">Limitless possibilities created through code.</p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="description-content">
          <p className="description">
            <TypingEffect2 >
              <span className="highlight-bold">'눈탱이 맞다'</span>에서 영감을 받아,<br className={`mo-br`} /> <span className="highlight">과도한 비용 없이 최고의 웹 서비스를 제공하는 것이 우리의 목표</span>입니다.<br /><br />
              눈이 내리는 밤처럼 감성적이고 분위기 있는<br className={`mo-br`} /> 디자인으로 여러분의 비즈니스를<br className={`mo-br`} /> 빛내드릴 뿐만 아니라,<br />
              풍부한 경험과 깊은 기술 이해를 바탕으로<br className={`mo-br`} /> 각 고객의 독특한 요구를 완벽하게<br className={`mo-br`} /> 충족시키는 것을 약속드립니다.<br /><br />
              기술 전문가가 직접 모든 프로젝트를<br className={`mo-br`} /> 세심하게 관리하며,<br />
              비즈니스가 직면한 도전을 해결하고 목표를<br className={`mo-br`} /> 달성하도록 최상의 결과를 보장합니다. <br /><br />
              눈밤과 함께라면, 고객님의 웹사이트는 단순한<br className={`mo-br`} /> 온라인 공간을 넘어,<br />
              고객님의 비전을 실현하는 창조적인<br className={`mo-br`} /> 마스터피스가 됩니다.
            </TypingEffect2>
          </p>
        </div>
      </div>
    );
};

export default About;
