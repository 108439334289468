import React from 'react';
import {Helmet} from 'react-helmet';
import About from '../components/About';

const AboutPage = () => {
  return (
    <div className="about-page sub-page">
      <Helmet>
        <title>회사소개 - 눈밤(SnowNight)</title>
        <meta name="description" content="속지마세요, 눈밤에서 시작하세요. 코드 하나로 창조하는 무한한 가능성"/>
        <meta name="keywords" content="눈밤, snownight, 눈탱이밤탱이, 웹에이전시, 웹제작, 반응형, 퍼블리싱, 그누보드, 유지보수, 웹개발"/>
        <link rel="canonical" href="https://snownight.kr/about"/>
      </Helmet>
      <About/>
    </div>
  );
};

export default AboutPage;
