import axios from 'axios';

const api = axios.create({
    baseURL: 'http://your-backend-url.com', // 백엔드 API의 기본 URL을 설정합니다.
    headers: {
        'Content-Type': 'application/json',
    },
});

export default api;
