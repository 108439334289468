import React, {useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import PortfolioPage from './pages/PortfolioPage';
import gsapAnimations from './gsapAnimations';
import MainPage from "./pages/MainPage";
import './styles/Main.scss';

const App = () => {
  // 눈내리는 효과
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; // 모바일 화면인지 감지
    const snowflakeInterval = isMobile ? 1000 : 500; // 모바일에서는 눈 내리는 양을 1/3로 감소

    const createSnowflake = () => {
      const snowflake = document.createElement('div');
      snowflake.classList.add('snowflake');
      snowflake.style.left = `${Math.random() * window.innerWidth}px`;
      snowflake.style.animationDuration = `${3 + Math.random() * 10}s`;
      snowflake.innerHTML = '❄';
      snowflake.style.position = 'fixed'; // 눈송이가 화면에 고정되도록 설정
      snowflake.style.top = '0'; // 화면 상단에서 시작
      document.body.appendChild(snowflake);
      setTimeout(() => {
        snowflake.remove();
      }, 8000); // 눈송이가 화면에서 사라지는 시간
    };

    const intervalId = setInterval(createSnowflake, snowflakeInterval); // 눈송이 생성 간격 설정

    // Clean-up function to clear interval
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    gsapAnimations();
  }, []);

  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/home" element={<HomePage/>}/>
        <Route path="/main" element={<MainPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        {/*<Route path="/services" element={<ServicesPage/>}/>*/}
        <Route path="/contact" element={<ContactPage/>}/>
        {/*<Route path="/portfolio" element={<PortfolioPage/>}/>*/}
      </Routes>
      <Footer/>
    </Router>
  );
};

export default App;
