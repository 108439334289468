import React, { useState, useEffect, useRef } from 'react';

const TypingAnimation = ({ text, speed }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const elementRef = useRef(null);

  const resetAnimation = () => {
    setDisplayedText('');
    setIndex(0);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio >= 1) {
            resetAnimation();
          }
        });
      },
      { threshold: [1] } // 50% 이상 보일 때 실행
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer && elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef]);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(text.slice(0, index + 1));
        setIndex(index + 1);
      }, speed);
      return () => clearTimeout(timer);
    }
  }, [index, text, speed]);

  return (
    <div ref={elementRef} style={{ whiteSpace: 'pre-wrap' }}>
      {displayedText}
    </div>
  );
};

export default TypingAnimation;
