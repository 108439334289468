import React, { useEffect, useRef, useState } from 'react';

const TypingEffect = ({ text }) => {
  const [key, setKey] = useState(0); // 컴포넌트 리렌더링을 위한 키
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setKey((prevKey) => prevKey + 1); // 키를 변경하여 컴포넌트 리렌더링
          }
        });
      },
      { threshold: 0.1 } // 요소가 10% 이상 보일 때 트리거
    );

    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);

  // 텍스트를 한 줄씩 나누고 각 줄을 다시 글자 단위로 나누어 처리
  const lines = text.split('\n');

  return (
    <div ref={containerRef} className="typing-container" key={key}>
      {lines.map((line, lineIndex) => (
        <div key={lineIndex} className="line">
          {line.split('').map((char, index) => (
            <span
              key={index}
              className="char"
              style={{ animationDelay: `${(lineIndex * line.length + index) * 0.1}s` }}
            >
              {char === ' ' ? '\u00A0' : char} {/* 공백 문자 처리 */}
            </span>
          ))}
          <br /> {/* 각 줄 끝에 줄바꿈 추가 */}
        </div>
      ))}
    </div>
  );
};

export default TypingEffect;
