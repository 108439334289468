import React from 'react';
import Contact from '../components/Contact';
import {Helmet} from "react-helmet";

const ContactPage = () => {
    return (
        <div className="contact-page sub-page">
          <Helmet>
            <title>문의하기 - 눈밤(SnowNight)</title>
            <meta name="description" content="눈밤 프로젝트 진행 문의하기"/>
            <meta name="keywords" content="눈밤, snownight, 눈탱이밤탱이, 웹에이전시, 웹제작, 반응형, 퍼블리싱, 그누보드, 유지보수, 웹개발"/>
            <link rel="canonical" href="https://snownight.kr/about"/>
          </Helmet>
            <Contact />
        </div>
    );
};

export default ContactPage;
