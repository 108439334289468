import React, { useState, useRef } from 'react';

const Contact = () => {
  const [fileName, setFileName] = useState('선택된 파일 없음');
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    manager: '',
    phone: '',
    email: '',
    referral: '',
    service_type: [],
    service_reference: '',
    service_budget: '',
    service_period: '',
    service_etc: '',
    agreement: false,
    service_file: null,
  });

  const refs = {
    name: useRef(null),
    phone: useRef(null),
    email: useRef(null),
    service_type: useRef(null),
    agreement: useRef(null),
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFormData({ ...formData, service_file: file });
    } else {
      setFileName('선택된 파일 없음');
      setFormData({ ...formData, service_file: null });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'agreement') {
        setFormData({ ...formData, [name]: checked });
      } else {
        const updatedServices = checked
          ? [...formData.service_type, value]
          : formData.service_type.filter((service) => service !== value);
        setFormData({ ...formData, service_type: updatedServices });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const scrollToElement = (ref) => {
    const headerOffset = document.querySelector('header').offsetHeight || 0; // 헤더 높이 가져오기
    const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY; // 요소의 위치 계산
    const offsetPosition = elementPosition - headerOffset - (window.innerHeight / 2) + (ref.current.offsetHeight / 2); // 중앙 위치로 조정

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const validateForm = () => {
    const nameRegex = /^[가-힣a-zA-Z\s]{2,10}$/;
    const phoneRegex = /^010-\d{4}-\d{4}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!nameRegex.test(formData.name)) {
      alert('이름은 2글자 이상 10글자 이하로 입력해주세요.');
      scrollToElement(refs.name);
      refs.name.current.focus();
      return false;
    }
    if (!phoneRegex.test(formData.phone)) {
      alert('연락처는 010-1234-5678 형식으로 입력해주세요.');
      scrollToElement(refs.phone);
      refs.phone.current.focus();
      return false;
    }
    if (!emailRegex.test(formData.email)) {
      alert('이메일을 올바른 형식으로 입력해주세요.');
      scrollToElement(refs.email);
      refs.email.current.focus();
      return false;
    }
    if (formData.service_type.length === 0) {
      alert('적어도 하나의 서비스 유형을 선택해주세요.');
      scrollToElement(refs.service_type);
      return false;
    }
    if (!formData.agreement) {
      alert('개인정보 수집 및 이용에 대한 동의를 체크해주세요.');
      scrollToElement(refs.agreement);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 폼 유효성 검사
    if (!validateForm()) return;

    const formPayload = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'service_type') {
        formPayload.append(key, formData[key].join(','));
      } else {
        formPayload.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch('https://api.snownight.kr/v1/consult_form.php', {
        method: 'POST',
        body: formPayload,
      });

      const result = await response.json();

      if (result.success) {
        alert('문의가 성공적으로 제출되었습니다.');
        // 입력값 초기화
        setFormData({
          name: '',
          company: '',
          manager: '',
          phone: '',
          email: '',
          referral: '',
          service_type: [],
          service_reference: '',
          service_budget: '',
          service_period: '',
          service_etc: '',
          agreement: false,
          service_file: null,
        });
        setFileName('선택된 파일 없음');
      } else {
        alert(`오류가 발생했습니다: ${result.message}`);
      }
    } catch (error) {
      console.error('문의 전송 중 오류가 발생했습니다:', error);
      alert('문의 제출에 실패했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <section id="contact" className="contact-section container">
      <div className={`contact-title-box`}>
        <h2 className="contact-title">Content</h2>
        <p className="contact-subtitle">
          눈밤과 함께 프로젝트 진행을 원할 경우<br/> 아래 내용을 확인 후 추천 답변을 작성해주시면 됩니다.
        </p>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-section">
          <h3 className="section-title">
            <span className={`section-title-sub`}>01. Information</span>
            기본 정보를 입력해주세요.
          </h3>
          <div className="form-group">
            <div className="form-field">
              <label>이름<span className={`required`}>*</span></label>
              <input type="text" name={`name`} value={formData.name} onChange={handleChange} ref={refs.name} />
            </div>
            <div className="form-field">
              <label>연락처 <span className={`required`}>*</span></label>
              <input type="text" name={`phone`} value={formData.phone} onChange={handleChange} ref={refs.phone} />
            </div>
          </div>
          <div className="form-group">
            <div className="form-field">
              <label>이메일<span className={`required`}>*</span></label>
              <input type="email" name={`email`} value={formData.email} onChange={handleChange} ref={refs.email} />
            </div>
            <div className="form-field">
              <label>눈밤을 알게된 경로</label>
              <input type="text" name={`referral`} value={formData.referral} onChange={handleChange}/>
            </div>
          </div>
          <div className="form-group">
            <div className="form-field">
              <label>회사 혹은 업체명</label>
              <input type="text" name={`company`} value={formData.company} onChange={handleChange}/>
            </div>
            <div className="form-field">
              <label>담당자명/직책</label>
              <input type="text" name={`manager`} value={formData.manager} onChange={handleChange}/>
            </div>
          </div>
        </div>

        <div className="form-section">
          <h3 className="section-title">
            <span className={`section-title-sub`}>02. Service</span>
            원하는 서비스가 어떤건가요?
          </h3>
          <div className="form-group checkboxes">
            <div className="checkbox-group" ref={refs.service_type}>
              {['홈페이지 신규 제작', '기존 홈페이지 리뉴얼', '쇼핑몰 제작', '원스크롤 페이지 제작', '유지보수', '기타(문의내용에 작성)'].map((label, index) => (
                <label key={index}>
                  <input type="checkbox" name="service_type" value={label} checked={formData.service_type.includes(label)} onChange={handleChange}/>
                  <span className="custom-checkbox"/>
                  {label}
                </label>
              ))}
            </div>
          </div>
          <div className="form-group">
            <div className="form-field">
              <label>레퍼런스 사이트</label>
              <input type="text" name={`service_reference`} placeholder="ex) https://snownight.kr" value={formData.service_reference} onChange={handleChange}/>
            </div>
            <div className="form-field">
              <label>퀄리티 대비 예상 비용</label>
              <input type="text" name={`service_budget`} placeholder="ex) 1000만원" value={formData.service_budget} onChange={handleChange}/>
            </div>
            <div className="form-field">
              <label>완성도 대비 오픈 예상 기간</label>
              <input type="text" name={`service_period`} placeholder="ex) 00년 0월 0일까지 오픈" value={formData.service_period} onChange={handleChange}/>
            </div>
            <div className="form-field">
              <label>기타 요구사항</label>
              <input type="text" name={`service_etc`} placeholder="ex) 반응형 웹사이트" value={formData.service_etc} onChange={handleChange}/>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>문의내용 작성 혹은 기획 자료 첨부</label>
          <div className="file-upload">
            <input
              type="file"
              id="file-input"
              name={`service_file`}
              onChange={handleFileChange}
            />
            <label htmlFor="file-input" className="custom-file-label">파일 선택</label>
            <span className="file-name">{fileName}</span>
          </div>
        </div>

        <div className="form-group agreement checkboxes">
          <label>개인정보 수집 및 이용에 대한 안내</label>
          <div className={`agreement-text`}>
            수집 받는 곳 : (주)눈밤<br/>
            수집 목적 : 문의·요청·견적 확인 및 상담<br/>
            수집 항목 : 이름, 연락처, 이메일, 회사 혹은 업체명, 담당자명/직책<br/>
            보유 기간 : 문의 확인 및 상담 종료 후 즉시 파기. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 일정 기간 동안 개인정보를 보관함.
          </div>
          <div className={`checkbox-group`} ref={refs.agreement}>
            <label className={`agreement-input `}>
              <input type="checkbox" name={`agreement`} value={`Y`} checked={formData.agreement} onChange={handleChange}/>
              <span className="custom-checkbox"/>
              위 내용에 동의합니다.
            </label>
          </div>
        </div>
        <div className={`submit-button-box`}>
          <button type="submit" className="submit-button">문의하기</button>
        </div>
      </form>
    </section>
  );
};

export default Contact;
