import React from 'react';
import { useLocation } from 'react-router-dom';
import FooterCTA from "./common/FooterCTA";

const Footer = () => {
  const location = useLocation(); // 현재 경로를 가져오기 위해 useLocation 사용
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 부드러운 스크롤링 효과
    });
  };

  return (
    <>
      {/* 현재 경로가 /contact가 아닌 경우에만 FooterCTA 렌더링 */}
      {location.pathname !== '/contact' && <FooterCTA />}
      <div className="footer">
        <div className="footer-text">
          <span>
            상호 : 눈밤 | 대표자 : 윤정주 <br className={`mo-br`} />
            <span className={`pc-show`}>|</span> 사업자 등록번호 : 203-08-53247
            <br className={`mo-br`} />
            <span className={`pc-show`}>|</span> 계좌번호 : 카카오뱅크 3333-31-0743947
          </span>
          <span>© 2024 Snownight. All rights reserved.</span>
        </div>
        <div className="back-to-top" onClick={scrollToTop}>
          <img src="/images/main/top_arrow.png" alt="Back to Top" />
        </div>
      </div>
    </>
  );
};

export default Footer;
