import React, {useState, useEffect, useRef} from 'react';
import FOG from 'vanta/dist/vanta.fog.min'
import TypingAnimation from "../components/common/TypingAnimation";

const HomePage = () => {
  // 안개효과
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(FOG({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        highlightColor: '#140426',
        midtoneColor: '#767aba',
        lowlightColor: '#ffffff',
        baseColor: '#140426',
        blurFactor: 0.56,
        speed: 2.40,
        zoom: 1.10
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div className="home-page sub-page">
      <div className={`main-box`}>
        <div className={`main-title`}>
          <h1>
            눈밤(Snownight)<br/>
            속지 마세요, 눈밤에서 시작하세요.
          </h1>
          <div>
            '눈탱이 맞다'에서 영감을 받아, 과도한 비용 없이 최고의 웹 서비스를 제공하는 것이 우리의 목표입니다.<br class="responsive-br"/>
            풍부한 경험과 깊은 기술 이해를 바탕으로 고객의 요구를 완벽하게 충족시키는 것을 약속드립니다.<br class="responsive-br"/>
            기술 전문가가 직접 모든 프로젝트를 세심하게 관리하며,<br class="responsive-br"/>
            비즈니스가 직면한 도전을 해결하고 목표를 달성하도록 최상의 결과를 보장합니다.<br class="responsive-br"/>
            눈밤과 함께라면, 귀하의 웹사이트는 단순한 온라인 공간을 넘어, 비전을 실현하는 창조적인 마스터피스가 됩니다.
          </div>
        </div>
      </div>
      <div className="content">
        <div ref={myRef} className="parallax parallax-1">
          <div className={`scrolling-box`}>
            <div className="scrolling-text">SNOWNIGHT</div>
            <div className="scrolling-text scrolling-text-2">
              SNOWNIGHT
            </div>
            <div className="scrolling-text scrolling-text-3">SNOWNIGHT</div>
          </div>
          <div className={`container text-box`}>
            <h3>
              <TypingAnimation text={`합리적인 가격\n맞춤형 고퀄리티 제작`} speed={100}/>
            </h3>
          </div>
        </div>
        <div className="parallax parallax-2">
          <div className={`test-box`}>
          <h2>서비스 설명</h2>
            <p>GASP 을 통한 스크롤 이벤트로 각 서비스 항목이 하나씩 나오도록 효과를 줄 예정</p>
          <ul>
            <li>맞춤형 웹사이트 개발 : 최신 기술을 활용하여 반응형 또는 PC/MO 분리형 맞춤형 웹사이트를 완벽하게 제작합니다. </li>
            <li>정기적 웹사이트 유지보수 : 지속적인 업데이트와 기술 지원을 통해 기능 추가, 버그 수정 등을 합리적인 가격으로 유지해 드립니다.</li>
            <li>웹 서비스 운영 : 웹사이트 운영을 위한 서버 관리부터 코드 유지보수까지, 웹서비스 전반을 안정적으로 관리합니다.</li>
          </ul>
          </div>
        </div>
        <div className="parallax parallax-3">
          <div className={`test-box`}>
            <h2>기술 스텍</h2>
            <p>HTML5 , CSS3(SCSS)</p>
            <p>Javascript , jQuery</p>
            <p>Canvas</p>
            <p>React.js</p>
            <p>Socket.io</p>
            <p>PHP(그누보드, 코드이그나이트 등)</p>
            <p>MySQL, MSSQL(SSMS)</p>
            <p>Node.js , Express</p>
            <p>linux system</p>
            <p>AWS(RDS,EC2,CLOUD FRONT,ROUTE53 등)</p>
          </div>
        </div>
        <div className="parallax parallax-3">
          <div className={`test-box`}>
            <h2>프로세스 설명</h2>
            <p>제작결정 -> 상담 -> 방향 -> 예산 -> 계약 -> 프로젝트진행</p>
          </div>
        </div>
        <div className="parallax parallax-4">
          <div className={`test-box`}>
            <h1>포트폴리오</h1>
            <p>그리드 형태에 포트폴리오 썸네일과 타이틀 표시</p>
            </div>
        </div>

      </div>
    </div>
  );
};

export default HomePage;
