import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(`/images/logo_wh.png`);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 부드러운 스크롤 효과
    });
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // 초기 렌더링에서 실행
    handleScroll();

    // 리사이즈 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 리스이즈 및 스크롤 이벤트 리스너 해제
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${isScrolled ? 'scrolled' : ''}`}>
      <div className={`container header`}>
        <Link to="/" onClick={handleScrollToTop}><img src={logoSrc} alt="logo" /></Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          {isOpen ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
        </button>
        <nav className={`${isOpen ? 'nav-open' : ''}`}>
          <ul>
            <li><Link to="/about" onClick={() => { toggleMenu(); handleScrollToTop(); }}>회사소개</Link></li>
            <li><Link to="/services" onClick={() => { toggleMenu(); handleScrollToTop(); }}>서비스</Link></li>
            <li><Link to="/portfolio" onClick={() => { toggleMenu(); handleScrollToTop(); }}>포트폴리오</Link></li>
            <li><Link to="/contact" onClick={() => { toggleMenu(); handleScrollToTop(); }}><span className={`consultButton`}>문의하기</span></Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
